(function () {
  'use strict';

  class SettingsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    settings() {
      return this.$resource(`${this.env.api}/settings`);
    }

  }

  /**
   * @ngdoc service
   * @name components.service:SettingsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('SettingsRest', SettingsRest);
}());
